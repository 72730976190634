import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/Layout"
import Seo from "../../components/seo"
import SectionArticlePost from "../../components/Section/SectionArticlePost"

export const query = graphql`
  query ArticleQuery($slug: String!) {
    strapiArticle(slug: { eq: $slug }, status: { eq: "published" }) {
      strapiId
      title
      description
      content
      publishedAt(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
      author {
        name
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              quality: 95
            )
          }
        }
      }
    }
  }
`

const ArticlePageTemplate = ({ data }) => {
  const article = data.strapiArticle

  return (
    <Layout>
      <Seo title={article.title} description={article.description} />
      <SectionArticlePost article={article} />
    </Layout>
  )
}

export default ArticlePageTemplate
