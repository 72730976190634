import * as React from "react"
import Markdown from "react-markdown"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

import { Container } from "../../../styles/container"

import * as S from "./styled"

const SectionArticlePost = props => {
  const { article } = props

  return (
    <S.Section>
      <header className="header">
        <Container>
          <div className="figure">
            {article.image ? (
              <GatsbyImage
                className="img-wrapper"
                imgClassName="img"
                objectFit="cover"
                objectPosition="center"
                placeholder="blurred"
                alt={article.title}
                image={article.image.localFile.childImageSharp.gatsbyImageData}
              />
            ) : (
              <StaticImage
                className="img-wrapper"
                imgClassName="img"
                src="../../../images/brand.png"
                objectFit="cover"
                objectPosition="center"
                placeholder="blurred"
                alt="Imagem do artigo"
              />
            )}
          </div>
          <h1 className="title">{article.title}</h1>
          <h2 className="subtitle">{article.description}</h2>
        </Container>
      </header>

      <div className="content">
        <Container>
          <Markdown children={article.content} />
        </Container>
      </div>

      <footer className="footer">
        <Container>
          <small className="paragraph">
            Por {article.author?.name || `Anônimo`}, em {article.publishedAt}
          </small>
        </Container>
      </footer>
    </S.Section>
  )
}

export default SectionArticlePost
