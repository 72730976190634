import styled from "styled-components"

import { breakpoints, sizes } from "../../../styles/breakpoints"

export const Section = styled.section`
  padding-bottom: 60px;

  .figure {
    margin-top: 20px;
    height: 450px;
    border-radius: 5px;
    overflow: hidden;
    @media ${breakpoints.lessThan(sizes.tablet)} {
      height: 350px;
    }
    @media ${breakpoints.lessThan(sizes.phone)} {
      height: 310px;
    }
    @media ${breakpoints.lessThan(sizes.smaller)} {
      height: 260px;
    }

    .img-wrapper {
      width: 100%;
      height: 100%;

      .img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .header {
    text-align: center;

    .title {
      margin-top: 45px;
      font-size: 2.2rem;
      font-weight: bold;
      color: var(--color-primary);

      @media ${breakpoints.lessThan(sizes.desktop)} {
        font-size: 1.9rem;
      }
      @media ${breakpoints.lessThan(sizes.phone)} {
        font-size: 1.7rem;
        margin-top: 35px;
      }
    }

    .subtitle {
      margin-top: 15px;
      font-size: 1.2rem;
      font-weight: normal;
      color: var(--color-grayscale5);
      @media ${breakpoints.lessThan(sizes.desktop)} {
        font-size: 1.1rem;
      }
      @media ${breakpoints.lessThan(sizes.phone)} {
        font-size: 1.05rem;
      }
    }
  }

  .content {
    display: block;
    width: 100%;
    max-width: 850px;
    margin: 60px auto 0;
    color: var(--color-black);
    @media ${breakpoints.lessThan(sizes.phone)} {
      margin-top: 40px;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 20px 0;
      display: block;
      font-weight: bold;
      color: var(--color-primary);
      line-height: 1.5;
      @media ${breakpoints.lessThan(sizes.phone)} {
        margin: 15px 0;
      }
    }

    h1 {
      font-size: 1.4rem;
      @media ${breakpoints.lessThan(sizes.desktop)} {
        font-size: 1.3rem;
      }
    }
    h2 {
      font-size: 1.35rem;
      @media ${breakpoints.lessThan(sizes.desktop)} {
        font-size: 1.25rem;
      }
    }
    h3 {
      font-size: 1.3rem;
      @media ${breakpoints.lessThan(sizes.desktop)} {
        font-size: 1.2rem;
      }
    }
    h4 {
      font-size: 1.25rem;
      @media ${breakpoints.lessThan(sizes.desktop)} {
        font-size: 1.15rem;
      }
    }
    h5 {
      font-size: 1.2rem;
      @media ${breakpoints.lessThan(sizes.desktop)} {
        font-size: 1.1rem;
      }
    }
    h6 {
      font-size: 1.1rem;
      @media ${breakpoints.lessThan(sizes.desktop)} {
        font-size: 1.05rem;
      }
    }

    span,
    p,
    ul > li,
    a {
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 1.6;
      margin-bottom: 30px;
      color: var(--color-grayscale6);
      @media ${breakpoints.lessThan(sizes.desktop)} {
        font-size: 1.1rem;
      }
      @media ${breakpoints.lessThan(sizes.phone)} {
        font-size: 1.05rem;
      }
    }

    b {
      font-weight: bold;
    }

    i {
      font-style: italic;
    }

    ul {
      list-style: none;
      padding: 0 0 0 15px;
      margin: 0 0 20px 0;
      margin-bottom: 20px;

      li {
        position: relative;
        padding: 0 0 0 18px;
        margin: 0 0 5px 0;

        &::before {
          content: "";
          position: absolute;
          top: 12px;
          left: 0;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: var(--color-secondary);
        }
      }
    }

    figure {
      margin-bottom: 20px;

      img {
        max-width: 100%;
        width: 100%;
        margin: 20px 0 10px;
      }

      figcaption {
        margin-left: 15px;
        color: var(--color-grayscale5);
        font-size: 0.85rem;
        letter-spacing: 1px;
        font-weight: 300;
      }
    }

    img {
      max-width: 100%;
      width: 100%;
      margin-bottom: 25px;
    }

    blockquote {
      position: relative;
      font-size: 1.2rem;
      font-weight: 300;
      color: var(--color-primary);
      border-color: var(--color-secondary);
      border-left-width: 3px;
    }

    a {
      color: var(--color-secondary);
      border: none;
      text-decoration: none;
      outline: none;
      display: inline-block;
      transition: all ease 0.2s;
      font-weight: 600;
      margin-bottom: 0;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .footer {
    text-align: right;
    margin-top: 100px;
    @media ${breakpoints.lessThan(sizes.desktop)} {
      margin-top: 80px;
    }
    @media ${breakpoints.lessThan(sizes.phone)} {
      margin-top: 60px;
      text-align: center;
    }

    .paragraph {
      font-size: 0.95rem;
      color: var(--color-grayscale5);
    }
  }
`
